import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import { omit } from 'lodash';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useLocation, useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import useEntitiesStore, { initialState } from '@/stores/EntitiesStore';
import useModalStore from '@/stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';

import { getEntity } from '@/util/apiUtils/entities';
import { checkJsonValidity, updateTemplateJson } from '@/util/helper';
import { NotificationTypes } from '@/util/resources';

import useNavigateWithParams from '@/components/gui/shared/navigation/useNavigateWithParams';
import { EmptyPage, MobileEmptyPage } from '@/components/Table/components/EmptyPage';
import { FormsTable } from '@/components/Table/components/Table';
import TableCopyModal from '@/components/Table/components/TableCopyModal';
import { TableFilters } from '@/components/Table/components/TableFilters';
import { TableHeader } from '@/components/Table/components/TableHeader';
import { TablePagination } from '@/components/Table/components/TablePagination';
import { TableSettings } from '@/components/Table/components/TableSettings';
import { baseFlippedProps, generateDesignerUrl, getQueryStrings } from '@/components/Table/helpers';
import { EntityStatuses, EntityTypes, FilterCategories, getRoute, OrderByTypes } from '@/components/Table/strings';

export class FormsTableContainer extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = { copyEntityIds: [] };
	}

	UNSAFE_componentWillMount() {
		this.getInitialData();
	}

	componentDidUpdate(prevProps) {
		// fetch new entities when pagination, fliters or type changes
		this.getUpdatedEntities(prevProps);
	}

	componentWillUnmount() {
		// remove selected table rows
		const { selectedEntitiesIds } = this.props;
		if (selectedEntitiesIds) this.removeSelectedEntitiesIds();

		this.props.changeEntityData({ pageFilters: initialState.pageFilters, formFilters: initialState.formFilters });
	}

	render() {
		return this.renderMainWrapper();
	}

	renderMainWrapper = () => {
		const entityType = this.getEntityType();
		return (
			<Flipper spring="noWobble" flipKey={this.props.entities.map((item) => item.Entity.Id).join('-')}>
				<div>
					<TableSettings
						onClose={this.onClose}
						onChange={this.onChangeForm}
						onSubmit={this.onSubmit}
						takeEntityAction={this.takeEntityAction}
						activeEntity={this.props.activeEntity}
						entityForm={this.props.entityForm}
						webhooks={this.props.webhooks}
					/>
					<Box>
						{this.renderHeader(entityType)}
						{this.renderTableContent(entityType)}
					</Box>

					<TableCopyModal
						isOpen={Boolean(this.state.copyEntityIds.length)}
						onClose={() => this.setState((state) => ({ ...state, copyEntityIds: [] }))}
						entityIds={this.state.copyEntityIds}
					/>
				</div>
			</Flipper>
		);
	};

	renderHeader = (entityType) => {
		const { totalFilteredEntities, loaders, isFiltered } = this.props;

		return (
			<TableHeader
				loader={loaders.table}
				isFiltered={isFiltered}
				totalFilteredEntities={totalFilteredEntities}
				entityType={entityType}
				initiateCreateNew={this.initiateCreateNew}
			/>
		);
	};

	renderTableContent = (entityType) => {
		const { entities, loaders, totalFilteredPagination, totalFilteredEntities, isFiltered } = this.props;
		const totalTablePages = totalFilteredPagination;

		const mainContent = (
			<>
				{isFiltered && !entities.length && !loaders.table && this.renderNoMatch(entityType)}
				{!isFiltered && !entities.length && !loaders.general && this.renderEmptyTable(entityType)}
				{(entities.length > 0 || loaders.table) && this.renderTable(entityType)}
				{totalTablePages && totalTablePages > 1 ? this.renderPagination(totalTablePages, totalFilteredEntities) : null}
			</>
		);

		return (
			<Box p={[2.5, 2.5, 8, 8]} maxW={2000} m="0 auto">
				{this.renderFilters(!(!isFiltered && !entities.length && !loaders.general), entityType)}
				{mainContent}
			</Box>
		);
	};

	renderPagination = (totalTablePages, totalFilteredEntities) => {
		const pagination = this.getPagination();

		return (
			<Flipped flipId="forms-table-pagination" {...baseFlippedProps}>
				<TablePagination
					currentPage={pagination}
					totalPages={totalTablePages}
					onClick={this.onChangePagination}
					totalItems={totalFilteredEntities}
					showPageLabel
				/>
			</Flipped>
		);
	};

	renderNoMatch = (entityType) => {
		return (
			<Flipped flipId="no-filter-match" {...baseFlippedProps}>
				<div>
					<Text
						display="flex"
						justifyContent="center"
						my={8}
						fontSize="xl"
					>{`No ${entityType.labels.fullPlural} match your criteria`}</Text>
				</div>
			</Flipped>
		);
	};

	renderEmptyTable = (entityType) => {
		return (
			<>
				<Box display={['none', 'none', 'block', 'block']}>
					<Flipped flipId="forms-table-empty" {...baseFlippedProps}>
						<div>
							<EmptyPage title={`No ${entityType.labels.fullPlural} yet`} />
						</div>
					</Flipped>
				</Box>
				<MobileEmptyPage
					title={`No ${entityType.labels.fullPlural.toLowerCase()}. Log in to your desktop to create your ${entityType.labels.full.toLowerCase()}.`}
					src={entityType.images.empty}
				/>
			</>
		);
	};

	renderFilters = (show, entityType) => {
		// if (!show) {
		// 	return null;
		// }

		const { allTags, hasSelectedEntities, selectedEntities, loaders, selectedEntitiesIds } = this.props;

		const filters = this.getFiltersByType();

		return (
			<Flipped flipId="forms-table-filters" {...baseFlippedProps}>
				<div>
					<TableFilters
						selectedEntities={selectedEntities}
						selectedEntitiesIds={selectedEntitiesIds}
						loaders={loaders}
						filters={filters}
						allTags={allTags}
						entityType={entityType}
						hasSelectedEntities={hasSelectedEntities}
						onSetManyTags={this.onSetManyTags}
						onFilterChange={this.onFilterChange}
						takeManyAction={this.takeManyAction}
						removeSelectedEntitiesIds={this.removeSelectedEntitiesIds}
					/>
				</div>
			</Flipped>
		);
	};

	renderTable = (entityType) => {
		const { allTags, entities, analytics, selectedEntitiesIds } = this.props;

		const filters = this.getFiltersByType();

		const order = filters.orderBy;
		const allSelected = entities.length ? entities.length === selectedEntitiesIds.length : false;

		return (
			<FormsTable
				loaders={{ table: this.props.loaders.table, tableDataUpdate: this.props.loaders.tableDataUpdate }}
				entityType={entityType}
				allSelected={allSelected}
				entities={entities}
				analytics={analytics}
				order={order}
				activeTags={filters.tags}
				selectedEntitiesIds={selectedEntitiesIds}
				allTags={allTags}
				changeOrder={this.changeOrder}
				onToggleAll={this.selectAllEntities}
				selectEntity={this.selectEntity}
				takeEntityAction={this.takeEntityAction}
				onTagsSave={this.onTagsSave}
				onTagsRemove={this.onTagsRemove}
				onTagClick={this.onTagClick}
			/>
		);
	};

	onClose = () => {
		const input = {
			activeEntity: undefined,
			entityForm: initialState.entityForm,
		};

		if (this.props.changeEntityData) this.props.changeEntityData(input);
	};

	onChangeForm = (data) => {
		const input = {
			entityForm: {
				...this.props.entityForm,
				[data.type]: data.value,
			},
		};

		if (this.props.changeEntityData) this.props.changeEntityData(input);
	};

	onSubmit = () => {
		const { activeEntity, entityForm } = this.props;
		const filters = this.getFilters();
		if (activeEntity) {
			if (entityForm.webhookId) this.assignWebhook(entityForm.webhookId);
			this.props.updateEntity({
				formData: entityForm,
				activeEntity: activeEntity,
				callback: () => this.getEntities({ ...filters }),
			});
		} else {
			// this.props.createEntity({ formData: this.props.entityForm, callback: () => this.getEntities({ ...filters }) });
			this.props.createEntity({ formData: entityForm, callback: (id, entity) => this.redirectToDesigner(id, entity) });
		}
	};

	initiateCreateNew = () => {
		this.onChangeForm({ type: 'show', value: true });
	};

	changeOrder = (orderBy) => {
		this.onFilterChange({ dataLabel: 'orderBy', value: orderBy });
	};

	onTagsSave = (tags, entityId) => {
		const foundPage = this.findEntityById(entityId || '');
		const { type } = this.getEntityType();

		if (foundPage) {
			this.props.setTags({ entityId: entityId || '', tags, type });
		}
	};

	onTagsRemove = (data) => {
		const foundPage = this.findEntityById(data.itemId || '');
		const { type } = this.getEntityType();

		if (foundPage) {
			const tags =
				foundPage.Tags &&
				foundPage.Tags.map((Tag) => {
					return Tag.Tag;
				});

			tags.splice(data.index, 1);

			this.props.setTags({ entityId: data.itemId || '', tags, type });
		}
	};

	onTagClick = (tag) => {
		const filters = this.getFiltersByType();

		const newTags = [...filters.tags];

		if (newTags.includes(tag)) {
			const index = newTags.findIndex((item) => {
				return item === tag;
			});

			newTags.splice(index, 1);
		} else {
			newTags.push(tag);
		}

		this.onFilterChange({ dataLabel: 'tags', value: newTags });
	};

	onSetManyTags = (tag, remove) => {
		const { selectedEntities } = this.props;
		const { type } = this.getEntityType();

		const payloadTags = selectedEntities.map((entity) => {
			let newTags = [];

			if (remove) {
				const removedTags = entity.Tags.filter((entityTag) => {
					return entityTag.Tag !== tag;
				});
				newTags = removedTags.map((entityTag) => {
					return entityTag.Tag;
				});
			} else {
				newTags = entity.Tags.map((entityTag) => {
					return entityTag.Tag;
				});
				newTags.push(tag);
			}

			return {
				entityId: entity.Entity.Id,
				type,
				tags: newTags,
			};
		});

		this.props.setManyTags({ newTags: payloadTags, type });
	};

	onFilterChange = (data) => {
		const filters = this.getFiltersByType();
		const filterType = this.getFilterCategory();

		const input = {
			[filterType]: {
				...filters,
				...{ [data.dataLabel]: data.value },
			},
		};

		if (this.props.changeEntityData) this.props.changeEntityData(input);
	};

	selectEntity = (data) => {
		const { selectedEntitiesIds } = this.props;

		if (data.dataLabel) {
			let input = {};

			if (data.value) {
				input = {
					selectedEntitiesIds: selectedEntitiesIds.concat(data.dataLabel),
				};
			} else {
				const indexOfSelected = selectedEntitiesIds.indexOf(data.dataLabel);
				const newEntities = [...selectedEntitiesIds];
				newEntities.splice(indexOfSelected, 1);
				input = {
					selectedEntitiesIds: newEntities,
				};
			}

			if (this.props.changeEntityData) this.props.changeEntityData(input);
		}
	};

	selectAllEntities = (selectAll) => {
		const { entities } = this.props;
		const selectedEntitiesIds = [];

		if (selectAll) {
			entities.forEach((entity) => {
				selectedEntitiesIds.push(entity.Entity.Id);
			});
		}

		const input = {
			selectedEntitiesIds,
		};

		if (this.props.changeEntityData) this.props.changeEntityData(input);
	};

	getEntity = (entityId) => {
		this.props.getEntity(entityId);
	};

	getAllBlueprints = (entityId) => {
		this.props.getAllBlueprints(entityId);
	};

	duplicateEntity = (entityId) => {
		const filters = this.getFilters();
		this.props.duplicateEntity({ entityId, type: filters.type, callback: () => this.getEntities({ ...filters }) });
	};

	publishEntity = async (entityId) => {
		const entityRsp = await getEntity(entityId, false);
		const jsonData = entityRsp.data?.Blueprints.JsonContent;
		const jsonDataParsed = jsonData && JSON.parse(jsonData);

		// LEGACY
		const content = jsonDataParsed?.newsletter ? jsonDataParsed?.newsletter : jsonDataParsed?.content;

		if (content && content.rows.length) {
			const updatedJson = updateTemplateJson(content);
			const { validity } = checkJsonValidity(updatedJson.rows, updatedJson.lastPage);

			if (validity.hasErrors) {
				return useModalStore.getState().showGeneralModal({
					title: 'Unable to activate due to errors',
					message: 'Form can’t be activated because it contains errors. Edit the form to fix the errors.',
					okLabel: 'Edit form',
					onOk: () => this.takeEntityAction('design', entityId),
				});
			}

			const onOk = () => {
				const filters = this.getFilters();
				this.props.publishEntity({ entityId, type: filters.type, callback: () => this.getEntities(filters) });
			};

			useModalStore.getState().showGeneralModal({
				title: 'Activate form?',
				message: `Are you sure you want to activate this Form? This action is not reversible.`,
				okLabel: 'Activate',
				onOk,
			});
		} else {
			showNotification({
				type: NotificationTypes.ERROR,
				text: 'Form is missing a design.',
			});
		}
	};

	refreshEntity = (entityId) => {
		this.props.refreshEntity(entityId);
	};

	publishManyEntities = () => {
		const { selectedEntities } = this.props;
		const filters = this.getFilters();

		const payloadIds = [];
		const inactiveIds = [];
		const draftIds = [];
		const errorIds = [];

		selectedEntities.forEach((item) => {
			if (item.Entity.Status === EntityStatuses.inactive) {
				inactiveIds.push(item.Entity.Id);
			} else if (item.Entity.Status === EntityStatuses.draft) {
				draftIds.push(item.Entity.Id);
			}
		});

		if (draftIds.length) {
			showNotification({
				type: NotificationTypes.INFO,
				text: `Cannot activate draft form: design or webhook settings missing`,
			});
		}

		if (inactiveIds.length) {
			Promise.all(inactiveIds.map((item) => getEntity(item))).then((resp) => {
				resp.forEach((entityRsp) => {
					const jsonData = entityRsp.data?.Blueprints.JsonContent;
					const jsonDataParsed = jsonData && JSON.parse(jsonData);

					// LEGACY
					const content = jsonDataParsed?.newsletter ? jsonDataParsed?.newsletter : jsonDataParsed?.content;

					const updatedJson = updateTemplateJson(content);
					const { validity } = checkJsonValidity(updatedJson.rows, updatedJson.lastPage);

					if (validity.hasErrors) {
						errorIds.push(entityRsp.data?.Entity.Id);
					} else {
						payloadIds.push(entityRsp.data?.Entity.Id);
					}
				});

				if (errorIds.length) {
					showNotification({
						type: NotificationTypes.ERROR,
						text: `Forms with design errors could not be activated`,
					});
				}

				if (payloadIds.length) {
					this.props.publishManyEntities({
						entityIds: payloadIds,
						type: filters.type,
						callback: () => this.getEntities(filters),
					});
				}
			});
		}
	};

	// unpublishEntity = (entityId) => {
	// 	const filters = this.getFilters();
	// 	this.props.unpublishEntity({ entityId, type: filters.type, callback: () => this.getEntities(filters) });
	// };

	// unpublishManyEntities = () => {
	// 	const { selectedEntitiesIds } = this.props;
	// 	const filters = this.getFilters();
	// 	this.props.unpublishManyEntities({
	// 		entityIds: selectedEntitiesIds,
	// 		type: filters.type,
	// 		callback: () => this.getEntities(filters),
	// 	});
	// };

	deleteEntity = (entityId) => {
		const filters = this.getFilters();

		const entity = this.props.entities.find((item) => {
			return item.Entity.Id === entityId;
		});

		if (entity) {
			this.props.deleteEntity({
				callback: () => this.getEntities({ ...filters }),
				entityId,
				name: entity.Entity.Name,
				type: filters.type,
			});
		}
	};

	deleteManyEntities = () => {
		const { selectedEntitiesIds } = this.props;
		const filters = this.getFilters();
		this.props.deleteManyEntities({
			entityIds: selectedEntitiesIds,
			type: filters.type,
			callback: () => this.getEntities({ ...filters }),
		});
	};

	archiveEntity = (entityId) => {
		const filters = this.getFilters();

		const entity = this.props.entities.find((item) => item.Entity.Id === entityId);

		if (entity)
			this.props.archiveEntity({
				callback: () => {
					this.getEntities({ ...filters });
					this.removeSelectedEntitiesIds();
				},
				entityId,
				name: entity.Entity.Name,
				isActive: entity.Entity.Status === 1,
			});
	};

	archiveManyEntities = () => {
		const { selectedEntities } = this.props;

		const filters = this.getFilters();

		const unarchivedEntities = selectedEntities.filter((entity) => !entity.Entity.Archived).map((entity) => entity.Entity.Id);

		if (!unarchivedEntities.length) return;

		this.props.archiveManyEntities({
			entityIds: unarchivedEntities,
			type: filters.type,
			callback: () => {
				this.getEntities({ ...filters });
				this.removeSelectedEntitiesIds();
			},
		});
	};

	unarchiveEntity = (entityId) => {
		const filters = this.getFilters();

		const entity = this.props.entities.find((item) => item.Entity.Id === entityId);

		if (entity)
			this.props.unarchiveEntity({
				callback: () => {
					this.getEntities({ ...filters });
					this.removeSelectedEntitiesIds();
				},
				entityId,
				name: entity.Entity.Name,
			});
	};

	unarchiveManyEntities = () => {
		const { selectedEntities } = this.props;

		const filters = this.getFilters();

		const archivedEntities = selectedEntities.filter((entity) => entity.Entity.Archived).map((entity) => entity.Entity.Id);

		if (!archivedEntities.length) return;

		this.props.unarchiveManyEntities({
			entityIds: archivedEntities,
			type: filters.type,
			callback: () => {
				this.getEntities({ ...filters });
				this.removeSelectedEntitiesIds();
			},
		});
	};

	takeEntityAction = (action, entityId) => {
		switch (action) {
			case 'design':
				this.redirectToDesigner(entityId);
				break;

			case 'duplicate':
				this.duplicateEntity(entityId);
				break;

			case 'settings':
				this.getEntity(entityId);
				break;

			case 'delete':
				this.deleteEntity(entityId);
				break;

			case 'archive':
				this.archiveEntity(entityId);
				break;

			case 'unarchive':
				this.unarchiveEntity(entityId);
				break;

			case 'activate':
				this.publishEntity(entityId);
				break;

			case 'refresh':
				this.refreshEntity(entityId);
				break;

			case 'copy':
				this.setState((state) => ({ ...state, copyEntityIds: [entityId] }));
				break;

			// case 'publish':
			// 	this.publishEntity(entityId);
			// 	break;

			// case 'deactivate':
			// 	this.unpublishEntity(entityId);
			// 	break;

			// case 'unpublish':
			// 	this.unpublishEntity(entityId);
			// 	break;

			default:
				break;
		}
	};

	takeManyAction = (action) => {
		switch (action) {
			case 'delete':
				this.deleteManyEntities();
				break;

			case 'publish':
				this.publishManyEntities();
				break;

			// case 'unpublish':
			// 	this.unpublishManyEntities();
			// 	break;

			case 'archive':
				this.archiveManyEntities();
				break;

			case 'unarchive':
				this.unarchiveManyEntities();
				break;

			case 'copy':
				this.setState((state) => ({ ...state, copyEntityIds: this.props.selectedEntitiesIds }));
				break;

			default:
				break;
		}
	};

	assignWebhook = (webhookId) => {
		const { activeEntity } = this.props;
		const entityId = activeEntity.Entity.Id;
		if (activeEntity) {
			if (activeEntity.WebhookSettings) {
				this.props.reassignWebhook(webhookId, entityId);
			} else {
				this.props.assignWebhook(webhookId, entityId);
			}
		}
	};

	redirectToDesigner = (entityId, entityArg) => {
		const entity = entityArg ? entityArg : this.props.entities.find((item) => item.Entity.Id === entityId);
		if (entity) {
			this.changePath(generateDesignerUrl(entityId, entity.Entity.Status === EntityStatuses.active));
		}
	};

	onChangePagination = (e, pagination) => {
		const { route } = this.getEntityType();
		this.changePath(getRoute.root(route, 'list', pagination));
	};

	changePath = (path) => {
		this.props.navigate(path + `${this.props.location.search}`);
	};

	findEntityById = (id) => {
		const { entities } = this.props;
		return (
			entities &&
			entities.find((entity) => {
				return entity.Entity.Id === id;
			})
		);
	};

	getEntityType = (props) => {
		const routeParam = props ? props.params.type : this.props.params.type;
		return routeParam === EntityTypes.landingPage.route ? EntityTypes.landingPage : EntityTypes.form;
	};

	getPagination = () => {
		return parseInt(this.props.params.page, 10) || undefined;
	};

	getEntities = (payload) => {
		this.props.getAllEntities(payload, this.props.location.search);
	};

	removeSelectedEntitiesIds = () => {
		const input = {
			selectedEntitiesIds: [],
		};

		if (this.props.changeEntityData) this.props.changeEntityData(input);
	};

	getFilterCategory = () => {
		const { route } = this.getEntityType();
		return FilterCategories[route];
	};

	getFiltersByType = (props) => {
		return props ? props[this.getFilterCategory(props)] : this.props[this.getFilterCategory()];
	};

	getFilters = () => {
		const filters = this.getFiltersByType();

		const pagination = this.getPagination();
		const { type, route } = this.getEntityType();

		return {
			...filters,
			pagination,
			routeParam: route,
			status: filters.status,
			type,
		};
	};

	getByFilters = () => {
		const search = this.props.location.search;
		const searchParams = getQueryStrings(search || '');
		const tags = searchParams.tags ? (Array.isArray(searchParams.tags) ? searchParams.tags : [searchParams.tags]) : [];

		const input = {
			[this.getFilterCategory(this.props)]: {
				name: searchParams.name || '',
				orderBy: searchParams.orderBy || this.getFiltersByType().orderBy,
				status: searchParams.status,
				subType: searchParams.subType,
				tags,
			},
		};

		if (this.props.changeEntityData) this.props.changeEntityData(input);
	};

	getInitialData = () => {
		const { type, route } = this.getEntityType();
		const pagination = this.getPagination();

		this.props.getTags(type);
		this.props.getWebhooks();

		this.getEntities({
			...this.getFiltersByType(),
			type,
			pagination,
			routeParam: route,
			withLoader: true,
		});
	};

	getUpdatedEntities = (prevProps) => {
		// page changed
		const prevPagination = prevProps.params.page;
		const currentPagination = this.props.params.page;
		const paginationChanged = prevPagination !== currentPagination;

		// type changed
		const prevType = prevProps.params.type;
		const currentType = this.props.params.type;
		const typeChanged = prevType !== currentType;

		// filters changed
		const prevFilters = this.getFiltersByType(prevProps);
		const currentFilters = this.getFiltersByType(this.props);
		// const currentFilters = typeChanged ? initialState.pageFilters : this.getFiltersByType(this.props);

		// console.log(typeChanged, currentFilters);

		const filtersChanged = JSON.stringify(omit(prevFilters, 'orderBy')) !== JSON.stringify(omit(currentFilters, 'orderBy'));
		const sortingChanged = prevFilters.orderBy !== currentFilters.orderBy;

		const { type, route } = this.getEntityType();

		if (typeChanged || paginationChanged) {
			const { selectedEntitiesIds } = this.props;
			if (selectedEntitiesIds) this.removeSelectedEntitiesIds();
		}

		if (paginationChanged || typeChanged || sortingChanged || filtersChanged) {
			if (filtersChanged) {
				this.props.getTags(type);
			}

			this.getEntities({
				delay: prevFilters.name !== currentFilters.name ? 500 : undefined,
				name: currentFilters.name,
				orderBy: currentFilters.orderBy,
				pagination: filtersChanged ? 1 : currentPagination,
				status: currentFilters.status,
				subType: currentFilters.subType,
				tags: currentFilters.tags,
				includeArchived: currentFilters.includeArchived,
				routeParam: route,
				withLoader: false,
				isUpdating: true,
				type,
			});
		}
	};
}

const TableContainerWrapper = (props) => {
	const storeData = useEntitiesStore((state) => {
		const { formFilters } = state;
		return {
			...state,
			isFiltered:
				// formFilters.name.length >= 3 || formFilters.orderBy !== OrderByTypes[3] || !!formFilters.status || !!formFilters.tags.length,
				!!formFilters.name.length ||
				formFilters.orderBy !== OrderByTypes[3] ||
				!!formFilters.status.length ||
				!!formFilters.tags.length ||
				formFilters.includeArchived,
			hasSelectedEntities: state.selectedEntitiesIds.length > 0 ? true : false,
			selectedEntities:
				state.entities.filter((item) => {
					return state.selectedEntitiesIds.includes(item.Entity.Id);
				}) || [],
		};
	}, shallow);
	const navigate = useNavigateWithParams();
	const location = useLocation();
	const params = useParams();
	return <FormsTableContainer {...storeData} params={params} {...props} navigate={navigate} location={location} />;
};

export default TableContainerWrapper;
