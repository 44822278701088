import React from 'react';

import { Box, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip } from '@chakra-ui/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Moment from 'moment';
import { useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import useContentViewStore from '@/stores/ContentViewStore';
import useEntitiesStore from '@/stores/EntitiesStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';

import { pathPrefix } from '@/util/helper';
import { contentViewMenuItemConstants as ViewTypes } from '@/util/resources';

import { Icon } from '@/components/gui/shared/Icon';
import LinkWithParams from '@/components/gui/shared/navigation/LinkWithParams';
import { EntityStatuses } from '@/components/Table/strings';

import ElementsMenu from './ElementsMenu.react';
import GeneralSettingsMenu from './GeneralSettingsMenu.react';
import StructureMenu from './StructureMenu.react';

class LeftSidebarMenu extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeTabIndex: 0,
			openDrawer: true,
		};
	}

	componentDidUpdate(prevProps) {
		/* istanbul ignore next */
		if (this.props.currentView === ViewTypes.DESIGN_VIEW && prevProps.currentView === ViewTypes.MOBILE_VIEW) {
			this.setState({ activeTabIndex: 0 });
		} else if (this.props.currentView === ViewTypes.MOBILE_VIEW && prevProps.currentView === ViewTypes.DESIGN_VIEW) {
			this.setState({ activeTabIndex: 0 });
		}
	}

	render() {
		return (
			<Box
				transition="0.2s"
				w={this.state.openDrawer ? 'sm' : '12'}
				display="flex"
				flexDirection="column"
				borderRight="1px solid"
				borderRightColor="chakra-border-color"
				data-testid="left-sidebar-menu"
				className="left-sidebar-menu"
				backgroundColor="chakra-subtle-bg"
			>
				<Box px={this.state.openDrawer ? '3' : '2'} h={'12'} display="flex" justifyContent="space-between" alignItems="center">
					{this.state.openDrawer && this.renderLatestDraftData()}

					<Tooltip label={this.state.openDrawer ? 'Collapse sidebar' : 'Expand sidebar'}>
						<IconButton
							aria-label={this.state.openDrawer ? 'Collapse sidebar' : 'Expand sidebar'}
							variant="ghost"
							colorScheme="neutral"
							size="sm"
							icon={<Icon path={this.state.openDrawer ? mdiChevronLeft : mdiChevronRight} />}
							data-testid="left-sidebar-toggle"
							onClick={() => this.setState({ openDrawer: !this.state.openDrawer })}
						/>
					</Tooltip>
				</Box>

				{this.state.openDrawer && (
					<Tabs
						variant="line"
						isFitted
						index={this.state.activeTabIndex}
						flex={1}
						overflowY="hidden"
						display="flex"
						flexDirection="column"
						onChange={(index) => this.setState({ activeTabIndex: index })}
					>
						<TabList>
							{this.props.currentView !== ViewTypes.MOBILE_VIEW && <Tab>Layouts</Tab>}
							{this.props.currentView !== ViewTypes.MOBILE_VIEW && <Tab>Items</Tab>}
							<Tab>Options</Tab>
						</TabList>
						<TabPanels flex={1} overflowY="auto">
							{this.props.currentView !== ViewTypes.MOBILE_VIEW && (
								<TabPanel p="0">
									<StructureMenu allowProdSave={this.props.allowProdSave || false} content={this.props.content} />
								</TabPanel>
							)}
							{this.props.currentView !== ViewTypes.MOBILE_VIEW && (
								<TabPanel pt="0">
									<ElementsMenu allowProdSave={this.props.allowProdSave || false} content={this.props.content} />
								</TabPanel>
							)}
							<TabPanel p="5">
								<GeneralSettingsMenu />
							</TabPanel>
						</TabPanels>
					</Tabs>
				)}
			</Box>
		);
	}

	renderLatestDraftData = () => {
		return this.props.latestDraft && !this.props.isPublishedEntity ? (
			<Text variant="small">
				<LinkWithParams to={pathPrefix() + '/drafts'} getAllParams>
					Versions
				</LinkWithParams>
				saved {Moment.unix(parseInt(this.props.latestDraft.dateSaved)).fromNow()}
			</Text>
		) : (
			<div />
		);
	};
}

const LeftSidebarMenuWrapper = (props) => {
	const leftSidebarData = useLeftSidebarStore((state) => {
		return {
			latestDraft: state.versions && state.versions.latestDraft,
			allowProdSave: state.allowProdSave,
		};
	}, shallow);
	const isPublishedEntity = useEntitiesStore((state) => state.activeEntity?.Entity.Status === EntityStatuses.active);
	const currentView = useContentViewStore((state) => state.currentView);
	const location = useLocation();

	return (
		<LeftSidebarMenu {...leftSidebarData} currentView={currentView} isPublishedEntity={isPublishedEntity} {...props} location={location} />
	);
};

export default LeftSidebarMenuWrapper;
