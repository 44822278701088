import React from 'react';

import { Button } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { fireEvent, formatTestId, pathPrefix } from '@/util/helper';

import LinkWithParams from '@/components/gui/shared/navigation/LinkWithParams';
import useNavigateWithParams from '@/components/gui/shared/navigation/useNavigateWithParams';

const ButtonVariants = {
	one: undefined,
	two: 'outline',
	three: 'ghost',
};

export const SimpleTopMenuItem = React.memo((props) => {
	const navigate = useNavigateWithParams();
	const location = useLocation();

	const onClick = (e) => {
		e.preventDefault();

		const proceed = () => {
			const path = pathPrefix() + props.href || pathPrefix();

			if (props.href) navigate(path + `${location.search}`);

			let closeDiv = document.getElementById('close-stuff');

			if (closeDiv) fireEvent(closeDiv, 'mousedown');

			if (props.onClick) {
				props.onClick(e);
			}

			e.target.blur();
		};

		proceed();
	};

	return (
		<LinkWithParams
			to={pathPrefix() + props.href || pathPrefix()}
			getAllParams
			onClick={(e) => onClick(e)}
			data-testid={formatTestId(props.label)}
		>
			<Button variant={ButtonVariants[props.type]} {...props.buttonProps} size={props.size}>
				{props.label}
				{props.children}
			</Button>
		</LinkWithParams>
	);
});

export default SimpleTopMenuItem;
