import { ChakraProvider, Link } from '@chakra-ui/react';
import { toastOptions } from '@sitecore/blok-theme';
import config from 'config/config';
import { ErrorMessages } from 'config/messages';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { getEntityId } from 'shared/src/utils/shared.js';

import useContentStore from '@/stores/ContentStore';
import { changeView } from '@/stores/ContentViewStore';
import useEntitiesStore, { initialState } from '@/stores/EntitiesStore';
import { showNotification } from '@/stores/NotificationStore';
import useStylingStore from '@/stores/StylingStore';

import { runEditor } from '@/module';
import { getAuth0ParamsFromUrl } from '@/util/auth/urlUtils';
import { clearAutosaves } from '@/util/autosave';
import { pathPrefix } from '@/util/helper';
import { GridOptions, NotificationTypes, contentViewMenuItemConstants as ViewTypes } from '@/util/resources';
import { theme } from '@/util/theme';
import WebStorage from '@/util/webStorage';

import App from '@/components/App.react';
import { AuthWrapper } from '@/components/AuthWrapper';
import { Conditions } from '@/components/Conditions/Conditions';
import MainBody from '@/components/gui/layout/MainBody.react';
import Preview from '@/components/gui/layout/Preview.react';
import { SettingsPage } from '@/components/gui/layout/SettingsPage.react';
import TemplateMarket from '@/components/gui/layout/TemplateMarket.react';
import { NoAccessLP } from '@/components/NoAccessLP';
import { RouteWrapper } from '@/components/RouteWrapper';
import StylingEdit from '@/components/Table/containers/StylingEdit';
import StylingList from '@/components/Table/containers/StylingList';
import TableContainer from '@/components/Table/containers/TableContainer';
import TableRoot from '@/components/Table/containers/TableRoot';
import WebhooksContainer from '@/components/Table/containers/WebhooksContainer';
import { EntitiesRoutes, getRoute } from '@/components/Table/strings';

export const Router = createBrowserRouter([
	{
		element: <AuthWrapper />,
		children: [
			{
				path: '/',
				element: <Navigate to={getRoute.root() + `?${getAuth0ParamsFromUrl('').toString()}`} />,
			},
			{
				Component: () => (
					<RouteWrapper onEnter={onNonEditorRouterEnter}>
						<TableRoot />
					</RouteWrapper>
				),
				children: [
					{ path: EntitiesRoutes.formList, element: <TableContainer /> },
					{ path: EntitiesRoutes.webhook, element: <WebhooksContainer /> },
					{ path: EntitiesRoutes.stylingList, element: <StylingList /> },
					{ path: EntitiesRoutes.stylingEdit, element: <StylingEdit /> },
				],
			},
			{
				path: pathPrefix(),
				Component: () => (
					<RouteWrapper onEnter={onEditorEnter} onLeave={onEditorLeave}>
						<App />
					</RouteWrapper>
				),
				children: [
					{
						index: true,
						Component: () => (
							<RouteWrapper onEnter={onMainBodyEnter}>
								<MainBody />
							</RouteWrapper>
						),
					},
					{ path: `${pathPrefix()}/send-test`, element: <MainBody /> },
					{ path: `${pathPrefix()}/script-menu`, element: <MainBody /> },
					{ path: `${pathPrefix()}/drafts`, element: <MainBody /> },
					{
						path: `${pathPrefix()}/comment-mode`,
						Component: () => (
							<RouteWrapper onEnter={onCommentsEnter}>
								<MainBody />
							</RouteWrapper>
						),
					},
					{
						path: `${pathPrefix()}/preview`,
						Component: () => (
							<RouteWrapper onEnter={onPreviewEnter}>
								<Preview />
							</RouteWrapper>
						),
					},
					{ path: `${pathPrefix()}/template-library`, element: <TemplateMarket /> },
					{ path: `${pathPrefix()}/conditions`, element: <Conditions /> },
					{
						path: `${pathPrefix()}/settings`,
						Component: () => (
							<RouteWrapper onEnter={onPreviewEnter}>
								<SettingsPage />
							</RouteWrapper>
						),
					},
				],
			},
		],
	},
	{
		path: '/no-access',
		Component: () => (
			<RouteWrapper onEnter={onNonEditorRouterEnter}>
				<NoAccessLP />,
			</RouteWrapper>
		),
	},
]);

/**
 * all routes and route changes MUST include the path_prefix from the config in order to account for the editor's deployment on a path for staging and prod
 */

const Routes = () => {
	return (
		<ChakraProvider theme={theme} toastOptions={toastOptions}>
			<RouterProvider router={Router} />
		</ChakraProvider>
	);
};

function onEditorEnter() {
	useContentStore.getState().clearContent();
	useStylingStore.getState().getAllStyles();
	runEditor();
}

function onEditorLeave() {
	clearAutosaves();
	useEntitiesStore.getState().changeEntityData({ activeEntity: undefined, entityForm: initialState.entityForm });
	useContentStore.getState().setData({ dataLoading: true });
	WebStorage.remove('canBeModified');
}

function onMainBodyEnter() {
	changeView(ViewTypes.DESIGN_VIEW);
}

async function onPreviewEnter(navigate) {
	const contentStore = useContentStore.getState();

	const { logicsErrors, isValid } = contentStore.checkLogicsValidity();

	if (contentStore.dataLoading) {
		setTimeout(() => {
			onPreviewEnter(navigate);
		}, 300);
		return;
	}

	let errorCb = () => showNotification({ text: ErrorMessages.PREVIEW_ERROR, type: NotificationTypes.ERROR });

	contentStore.setPreviewHtml({ content: contentStore.content }, null, errorCb, logicsErrors);

	if (!isValid) {
		showNotification({
			type: NotificationTypes.ERROR,
			text: (
				<>
					Fix invalid{' '}
					<Link
						onClick={() =>
							navigate(`/design/conditions?entityId=${getEntityId(config)}&${getAuth0ParamsFromUrl(window.location.search).toString()}`)
						}
					>
						logic
					</Link>{' '}
					conditions to preview the form with logic
				</>
			),
		});
	}
}

function onCommentsEnter() {
	useContentStore.getState().saveContentHelper({ grid: GridOptions.DISABLED });
}

function onNonEditorRouterEnter() {
	WebStorage.remove('canBeModified');
}

export default Routes;
