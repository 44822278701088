import { useEffect, useState } from 'react';

import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	Card,
	CardBody,
	CircularProgress,
	FormControl,
	FormLabel,
	Heading,
	Input,
	LinkBox,
	LinkOverlay,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';
import { Flipped, Flipper } from 'react-flip-toolkit';

import 'react-router-dom';

import useStylingStore from '@/stores/StylingStore';

import { Icon } from '@/components/gui/shared/Icon';
import MoreMenu from '@/components/gui/shared/MoreMenu';
import LinkWithParams from '@/components/gui/shared/navigation/LinkWithParams';
import useNavigateWithParams from '@/components/gui/shared/navigation/useNavigateWithParams';
import { EmptyPage } from '@/components/Table/components/EmptyPage';
import { baseFlippedProps } from '@/components/Table/helpers';
import { getRoute } from '@/components/Table/strings';

export const StylingListContainer = () => {
	const isLoading = useStylingStore((state) => state.isLoading);
	const [showNameModal, setShowNameModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [name, setName] = useState('');
	const [styleId, setStyleId] = useState('');

	const onRename = (name, id) => {
		setName(name);
		setStyleId(id);
		setShowNameModal(true);
	};

	const onDelete = (name, id) => {
		setName(name);
		setStyleId(id);
		setShowDeleteModal(true);
	};

	const onClose = () => {
		setName('');
		setStyleId('');
		setShowNameModal(false);
		setShowDeleteModal('');
	};

	useEffect(() => {
		useStylingStore.getState().getAllStyles();
	}, []);

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" h="100%">
				<CircularProgress isIndeterminate capIsRound color="primary" trackColor="neutral-color.200" />
			</Box>
		);
	}

	return (
		<Box px={7} maxW={2000} mx="auto">
			<StyleFormModal isOpen={showNameModal} onClose={onClose} name={name} setName={setName} styleId={styleId} />
			<StyleDeleteModal isOpen={showDeleteModal} onClose={onClose} name={name} styleId={styleId} />
			<Box display="flex" flexWrap="wrap" alignItems="center" pt={6} pb={0} m="0 auto">
				<Heading as="h1">Styles</Heading>
				<Button ml="auto" leftIcon={<Icon path={mdiPlus} />} onClick={() => setShowNameModal(true)}>
					Create style
				</Button>
			</Box>
			<Text variant="subtle" pt={2.5} pb={7} m="0 auto">
				Create style sets for your forms
			</Text>
			<StylingList onRename={onRename} onDelete={onDelete} onCreate={() => setShowNameModal(true)} />
		</Box>
	);
};

const StylingList = (props) => {
	const list = useStylingStore((state) => state.list);

	if (!list.length) {
		return <EmptyPage title="No styles yet" />;
	}

	return (
		<Flipper spring="noWobble" flipKey={list.map((item) => item.id).join('-')}>
			<div>
				{list.map((item) => {
					return (
						<Flipped {...baseFlippedProps} flipId={item.id} key={`${item.id}-flipped`}>
							<div>
								<StylingListItem onRename={props.onRename} onDelete={props.onDelete} {...item} />
							</div>
						</Flipped>
					);
				})}
			</div>
		</Flipper>
	);
};

const StylingListItem = (props) => {
	const duplicateStyle = useStylingStore((state) => state.duplicateStyle);
	const navigate = useNavigateWithParams();

	const onRename = () => {
		props.onRename(props.name, props.id);
	};

	const onDelete = () => {
		props.onDelete(props.name, props.id);
	};

	const availableActions = [
		{
			action: () => navigate(getRoute.stylingEdit('forms', props.id)),
			label: 'Edit',
		},
		{
			action: onRename,
			label: 'Rename',
		},
		{
			action: () => duplicateStyle(props.id),
			label: 'Duplicate',
		},
		{
			action: onDelete,
			label: 'Delete',
		},
	];

	return (
		<LinkBox as={Card} variant="outline" layerStyle="interactive.fill" mb={3} data-testid="styling-list-item">
			<CardBody display="flex" justifyContent="space-between" alignItems="center" gap="4" py="4">
				<LinkOverlay as={LinkWithParams} to={getRoute.stylingEdit('forms', props.id)}>
					<Text variant="strong" noOfLines={1}>
						{props.name} {props.isCss ? '(Code)' : ''}
					</Text>
				</LinkOverlay>
				<Box data-testid="styling-item-menu">
					<MoreMenu actions={availableActions} dataTestId="styles-item-menu" />
				</Box>
			</CardBody>
		</LinkBox>
	);
};

const StyleDeleteModal = ({ onClose, name, styleId, isOpen }) => {
	const deleteStyle = useStylingStore((state) => state.deleteStyle);

	const onDelete = () => {
		deleteStyle(styleId);
		onClose();
	};

	return (
		<AlertDialog isOpen={isOpen} onClose={onClose}>
			<AlertDialogOverlay />
			<AlertDialogContent>
				<AlertDialogHeader>Delete style</AlertDialogHeader>
				<AlertDialogBody>Are you sure to delete "{name}"?</AlertDialogBody>
				<AlertDialogFooter gap="2">
					<Button variant="ghost" onClick={onClose}>
						No
					</Button>
					<Button colorScheme="danger" onClick={onDelete}>
						Delete
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};

const StyleFormModal = ({ onClose, name, setName, styleId, isOpen }) => {
	const onSave = () => {
		if (styleId) {
			useStylingStore.getState().renameStyle({ name, id: styleId });
		} else {
			useStylingStore.getState().createStyle({ name });
		}
		onClose();
	};

	return (
		<Modal onClose={onClose} isOpen={isOpen} size="xl">
			<ModalOverlay data-testid="new-style-modal-overlay" />
			<ModalContent h="fit-content" overflow="auto">
				<ModalCloseButton />
				<ModalHeader>{styleId ? 'Rename style' : 'Create style'}</ModalHeader>
				<ModalBody>
					<Box mb={10}>
						<FormControl>
							<FormLabel>Name</FormLabel>
							<Input autoFocus data-testid="new-style-name" value={name} onChange={(e) => setName(e.target.value)} />
						</FormControl>
					</Box>
				</ModalBody>
				<ModalFooter gap="2">
					<Button onClick={onClose} variant="ghost">
						Cancel
					</Button>
					<Button data-testid="new-style-button" onClick={onSave} isDisabled={!name}>
						{styleId ? 'Save' : 'Add'}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default StylingListContainer;
