import React from 'react';

import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

import { updateSearchParamsIfNeeded } from '@/util/auth/tenant-parameter';
import { getAuth0ParamsFromUrl } from '@/util/auth/urlUtils';

interface LinkWithParamsProps extends ChakraLinkProps {
	to?: string;
	getAllParams: boolean;
}

const LinkWithParams: React.FC<LinkWithParamsProps> = ({ to = '', getAllParams, children, ...props }) => {
	const location = useLocation();

	const [baseUrl, queryString] = to.split('?');

	const urlParams = new URLSearchParams(getAllParams ? location.search : getAuth0ParamsFromUrl(location.search));

	if (queryString) {
		const existingParams = new URLSearchParams(queryString);
		existingParams.forEach((value, key) => {
			updateSearchParamsIfNeeded(urlParams, key, value);
		});
	}

	const newUrl = `${baseUrl}?${urlParams.toString()}`;

	return (
		<ChakraLink as={Link} to={newUrl} {...props}>
			{children}
		</ChakraLink>
	);
};

export default LinkWithParams;
