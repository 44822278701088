import React from 'react';

import { Box, Button, Link as ChakraLink, Heading, Image, Spacer, Text } from '@chakra-ui/react';
import config from 'config/config';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import useUserStore from '@/stores/UserStore.js';

import { claimsKeys, getUser } from '@/util/auth/auth.js';
import WebStorage from '@/util/webStorage.js';

import useNavigateWithParams from '@/components/gui/shared/navigation/useNavigateWithParams';
import { getRoute } from '@/components/Table/strings';

import { AccountMenu } from './AccountMenu';
import PocketNav from './PocketNav';

export const TableTopBar = ({ noAccess }) => {
	const tenants = useUserStore((state) => state.tenants);
	const user = getUser();
	const tenantId = user[claimsKeys.TENANT_ID];
	const currentTenant = tenants.find((tenant) => tenant.id === tenantId);
	const location = useLocation();
	const navigate = useNavigateWithParams();

	const handleNavigation = (path) => {
		const tenantInfo = WebStorage.get('tenant_info');
		const query = qs.parse(window.location.search);
		query.tenantName = tenantInfo.tenantName;
		query.organization = tenantInfo.organization;
		const newQueryString = qs.stringify(query);
		const newUrl = `${path}?${newQueryString}`;
		navigate(newUrl);
	};

	return (
		<Box as="header" layerStyle="section.topbar" flex="0 0 auto" display="flex" justifyContent="space-between" zIndex={2}>
			<Box display="flex" alignItems="center" gap="2">
				<ChakraLink href={config.xmc.dashboard_url} isExternal>
					<Image src="https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-xm_cloud" />
				</ChakraLink>
				<PocketNav />
				<Spacer mx={1} borderRight="1px solid" borderColor="gray.200" h="90%" alignSelf="center" />
				<Heading size="sm">Forms</Heading>
				{currentTenant && <Text mr="4">{currentTenant.displayName}</Text>}
				{!noAccess && (
					<>
						<TopBarButton
							label="Forms"
							path={getRoute.root('forms', 'list', 1)}
							isActive={location.pathname.startsWith('/forms/list')}
							onClick={handleNavigation}
						/>
						<TopBarButton
							label="Webhooks"
							path={getRoute.root('forms', 'webhook', 1)}
							isActive={location.pathname.startsWith('/forms/webhook')}
							onClick={handleNavigation}
						/>
						<TopBarButton
							label="Styles"
							path={getRoute.stylingList()}
							isActive={location.pathname.startsWith('/forms/styling')}
							onClick={handleNavigation}
						/>
					</>
				)}
			</Box>
			<Box>
				<AccountMenu />
			</Box>
		</Box>
	);
};

const TopBarButton = ({ label, path, isActive, onClick }) => {
	return (
		<Button variant="navigation" size="sm" isActive={isActive} onClick={() => onClick(path)}>
			{label}
		</Button>
	);
};
