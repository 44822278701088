import React from 'react';
import { createRoot } from 'react-dom/client';

import {
	Box,
	Button,
	ChakraProvider,
	Heading,
	IconButton,
	Image,
	Input,
	InputGroup,
	InputLeftElement,
	Link,
	Spinner,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { mdiChevronLeft, mdiChevronRight, mdiMagnify } from '@mdi/js';
import sitecoreTheme, { toastOptions } from '@sitecore/blok-theme';
import { Widget } from '@uploadcare/react-widget/en';
import { UploadClient } from '@uploadcare/upload-client';
import config from 'config/config';
import { ErrorMessages, SuccessMessages } from 'config/messages';
import { ImgixService } from 'shared/src/utils/imgixService.js';
import { generateGuid } from 'shared/src/utils/shared.js';

import useContentStore from '@/stores/ContentStore';
import { showNotification } from '@/stores/NotificationStore.js';

import { deleteUploadedFiles, getUploadedFiles, uploadImageByUrl } from '@/util/apiUtils/fileUpload.js';
import {
	downloadUnsplashImage,
	getGiphyBySearch,
	getGiphyByTrending,
	getMediaLibrary,
	getUnsplashImages,
	getUnsplashImagesBySearch,
} from '@/util/apiUtils/image.js';
import { getContentHubData } from '@/util/apiUtils/thirdParty.js';
import { makeApiGatewayCall } from '@/util/helper.js';
import { NotificationTypes } from '@/util/resources.js';

import { Icon } from '@/components/gui/shared/Icon';
import LinkWithParams from '@/components/gui/shared/navigation/LinkWithParams';

import giphyIcon from 'assets/images/giphy.png';

import { ContentHubIcon, ContentHubImages } from './ContentHubImages.react';
import { ContentHubIntegration } from './ContentHubIntegration.react';

const customTabs = {
	recent: 'recent',
	mediaLibrary: 'mediaLibrary',
	upgrade: 'upgrade',
	unsplash: 'unsplash',
	giphy: 'giphy',
	contenthub: 'contenthub',
};

const customRestrictedTabs = {
	unsplash: 'unsplash',
	giphy: 'giphy',
};

const integrationsTabs = {
	facebook: 'facebook',
	'google-drive': 'gdrive',
	dropbox: 'dropbox',
	instagram: 'instagram',
	onedrive: 'onedrive',
};

class ImageSetting extends React.PureComponent {
	widget = React.createRef();
	dialogApi;
	settings;
	uploadcare;

	client = new UploadClient({ publicKey: config.uploadCare.publicKey });

	state = {
		active: true,
		placeholderUrl: '',
		restrictedTabs: [],
	};

	componentDidUpdate(prevProps) {
		const { file } = this.props;

		if (file && this.props.id !== prevProps.id) {
			this.uploadDragDrop(file);
		} else if (prevProps.id !== this.props.id) {
			this.widget.current.openDialog();
		}
	}

	render() {
		if (!this.state.active) {
			return null;
		}

		return (
			<Widget
				ref={this.widget}
				publicKey={config.uploadCare.publicKey}
				imagesOnly={true}
				previewStep={true}
				tabs={`${customTabs.recent} ${
					customTabs.mediaLibrary
				} file url${this.getContentHubTab()}${this.getCustomTabs()}${this.getIntegrationsTabs()}${this.getUpgradeTab()}`}
				// tabs={`${customTabs.recent} file url${this.getCustomTabs()}${this.getIntegrationsTabs()}${this.getUpgradeTab()}`}
				onChange={this.onChange}
				customTabs={{
					[customTabs.recent]: (container, button, dialogApi, settings, name, uploadcare) => {
						this.dialogApi = dialogApi;
						this.settings = settings;
						this.uploadcare = uploadcare;
						this.renderCustomTab(container, button, {
							component: RecentTabContainer,
							title: 'Recent Images',
							icon: 'fa fa-picture-o',
							restrictedTabs: this.state.restrictedTabs,
							selectImage: (url, imageType) => this.selectImage(url, imageType, dialogApi, uploadcare, settings),
						});
					},
					[customTabs.unsplash]: (container, button, dialogApi, settings, name, uploadcare) => {
						this.renderCustomTab(container, button, {
							component: UnsplashContainer,
							title: 'Unsplash',
							icon: 'fa fa-camera',
							restrictedTabs: this.state.restrictedTabs,
							selectImage: (url) => this.storeUnsplashImage(url, dialogApi, uploadcare, settings),
						});
					},
					[customTabs.mediaLibrary]: (container, button, dialogApi, settings, name, uploadcare) => {
						this.renderCustomTab(container, button, {
							component: MediaLibraryContainer,
							title: 'Media Library',
							icon: 'fa fa-camera',
							restrictedTabs: this.state.restrictedTabs,
							selectImage: (url) => this.storeMediaLibraryImage(url, dialogApi, uploadcare, settings),
						});
					},
					[customTabs.giphy]: (container, button, dialogApi, settings, name, uploadcare) => {
						this.renderCustomTab(container, button, {
							component: GiphyContainer,
							title: 'Giphy',
							icon: 'fa fa-film',
							restrictedTabs: this.state.restrictedTabs,
							selectImage: (url) => this.storeGiphyImage(url, dialogApi, uploadcare, settings),
						});
					},
					[customTabs.contenthub]: (container, button, dialogApi, settings, name, uploadcare) => {
						this.renderCustomTab(container, button, {
							component: ContentHubContainer,
							title: 'Sitecore DAM',
							icon: ContentHubIcon,
							restrictedTabs: this.state.restrictedTabs,
							selectImage: (url) => this.storeContentHubImage(url, dialogApi, uploadcare, settings),
							isSvg: true,
						});
					},
					[customTabs.upgrade]: (container, button, dialogApi, settings, name, uploadcare) => {
						this.renderCustomTab(container, button, {
							component: UpgradeContainer,
							title: 'Unlock Features',
							icon: 'fa fa-lock',
							iconSize: '29px',
							restrictedTabs: this.state.restrictedTabs,
							selectImage: (url) => this.storeGiphyImage(url, dialogApi, uploadcare, settings),
						});
					},
				}}
			/>
		);
	}

	onChange = (image) => {
		const passLimit = 1920;
		const passesLimit = image.originalImageInfo.width > passLimit ? true : false;
		const customImageWidth = image.mimeType !== 'image/gif' && passesLimit ? `/-/resize/${passLimit}x/` : '';
		const imageUrl = image.cdnUrl + customImageWidth;

		if (image.mimeType === 'image/svg+xml') {
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.SVG_NOT_SUPPORTED });
			return;
		}
		uploadImageByUrl(imageUrl, 'EDITOR_IMAGES', image.name).then((data) => {
			if (data && !data.error) {
				const url = this.state.placeholderUrl ? this.state.placeholderUrl : data.newSrc;

				showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.IMAGE_UPLOADED });
				useContentStore.getState().setImage({
					type: this.props.type,
					url,
					imageType: image.mimeType,
					details: this.props.details,
				});

				// deleteUploadCareImage(image.uuid);

				this.setPlaceholderUrl('');
			} else {
				showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.IMAGE_NOT_UPLOADED });
				this.setPlaceholderUrl('');
			}
		});

		this.setState({ active: false }, () => this.setState({ active: true }));
	};

	renderCustomTab = (container, button, details) => {
		const buttonElement = button[0];
		buttonElement.title = details.title;

		let i;

		if (details.isSvg) {
			i = new DOMParser().parseFromString(details.icon, 'text/html').body.firstElementChild;
		} else {
			i = document.createElement('i');
			i.className = details.icon;
			i.style.margin = '24px 20px 20px';
			i.style.fontSize = details.iconSize || '18px';
		}

		buttonElement.removeChild(buttonElement.childNodes[0]);
		buttonElement.appendChild(i);

		const DynamicComponent = details.component;

		const root = createRoot(container[0]);
		const dialogRoot = this.dialogApi?.dialogElement?.[0] || container[0];

		root.render(
			// toastOptions are provided intentionally to avoid creating unnecessary portals
			<ChakraProvider
				theme={sitecoreTheme}
				disableGlobalStyle
				toastOptions={{
					...toastOptions,
					portalProps: { ...(toastOptions.portalProps || {}), containerRef: dialogRoot },
				}}
			>
				<DynamicComponent selectImage={details.selectImage} restrictedTabs={details.restrictedTabs} />
			</ChakraProvider>,
		);
	};

	selectImage = (url, imageType, dialogApi, uploadcare) => {
		useContentStore.getState().setImage({ type: this.props.type, url, imageType, details: this.props.details });
		uploadcare.closeDialog();
	};

	storeGiphyImage = (url, dialogApi, uploadcare, settings) => {
		// this.setPlaceholderUrl(url);

		// dialogApi.fail(() => {
		// 	this.setPlaceholderUrl('');
		// });

		this.storeImageToUploadcare(url, dialogApi, uploadcare, settings);
	};

	storeContentHubImage = (url, dialogApi, uploadcare, settings) => {
		this.storeImageToUploadcare(url, dialogApi, uploadcare, settings);
	};

	storeMediaLibraryImage = (url, dialogApi, uploadcare, settings) => {
		makeApiGatewayCall(url, 'get', null, null, {
			responseType: 'blob',
		}).then((res) => {
			if (res.status === 200) {
				this.storeImageToUploadcare(res.data, dialogApi, uploadcare, settings);
			}
		});
	};

	setPlaceholderUrl = (url) => {
		this.setState({ placeholderUrl: url });
	};

	storeUnsplashImage = (url, dialogApi, uploadcare, settings) => {
		const errorCb = () => {
			showNotification({ type: NotificationTypes.ERROR, text: 'Failed to upload image' });
		};

		const successCb = (newUrl) => {
			const customImageWidth = '&w=1920';
			const imageUrl = newUrl + customImageWidth;

			this.storeImageToUploadcare(imageUrl, dialogApi, uploadcare, settings);
		};

		downloadUnsplashImage(url, successCb, errorCb);
	};

	storeImageToUploadcare = (data, dialogApi, uploadcare, settings) => {
		this.client
			.uploadFile(data)
			.then((file) => {
				dialogApi.addFiles([uploadcare.fileFrom('uploaded', file.uuid, settings)]);
			})
			.catch((error) => {
				console.error(error);
				showNotification({ type: NotificationTypes.ERROR, text: 'Failed to upload image' });
			});
	};

	uploadDragDrop = (file) => {
		this.props.setLoader(true);
		setTimeout(() => {
			this.client
				.uploadFile(file)
				.then((uploadedFile) => {
					this.widget.current.openDialog();
					this.dialogApi.addFiles([this.uploadcare.fileFrom('uploaded', uploadedFile.uuid, this.settings)]);
					this.props.setLoader(false);
				})
				.catch((error) => {
					console.error(error);
					showNotification({ type: NotificationTypes.ERROR, text: 'Failed to upload image' });
				});
		}, 1);
	};

	getUpgradeTab = () => {
		if (this.state.restrictedTabs.length) {
			return ` ${customTabs.upgrade}`;
		}
		return '';
	};

	getContentHubTab = () => {
		return '';
		// return ` ${customTabs.contenthub}`;
	};

	getIntegrationsTabs = () => {
		return this.getTabs(integrationsTabs);
	};

	getCustomTabs = () => {
		return this.getTabs(customRestrictedTabs);
	};

	getTabs = (tabsObj) => {
		let tabs = '';

		const tabKeys = Object.keys(tabsObj);

		if (tabKeys.length) {
			tabKeys.forEach((tab) => {
				tabs = tabs + ` ${tabsObj[tab]}`;
			});
		}

		return tabs;
	};
}

export const RecentTabContainer = (props) => {
	const fetchUploadedImages = (page, successCb, errorCb) => {
		return getUploadedFiles(page, null, successCb, errorCb);
	};

	const fetchUploadedImagesBySearch = (search, page, successCb, errorCb) => {
		return getUploadedFiles(page, search, successCb, errorCb);
	};

	const removeImage = (id, successCb, errorCb) => {
		return deleteUploadedFiles(id, successCb, errorCb);
	};

	return (
		<SearchableImageContainer
			type={customTabs.recent}
			fetchAllImages={fetchUploadedImages}
			fetchImagesBySearch={fetchUploadedImagesBySearch}
			removeImage={removeImage}
			selectImage={props.selectImage}
			initialPage={1}
		/>
	);
};

export const UnsplashContainer = (props) => {
	return (
		<SearchableImageContainer
			poweredBy={{
				label: 'Powered By Unsplash',
				image: '',
			}}
			type={customTabs.unsplash}
			fetchAllImages={getUnsplashImages}
			fetchImagesBySearch={getUnsplashImagesBySearch}
			selectImage={props.selectImage}
			initialPage={1}
		/>
	);
};

export const GiphyContainer = (props) => {
	return (
		<SearchableImageContainer
			poweredBy={{
				label: 'Powered By GIPHY',
				image: giphyIcon,
			}}
			type={customTabs.giphy}
			fetchAllImages={getGiphyByTrending}
			fetchImagesBySearch={getGiphyBySearch}
			selectImage={props.selectImage}
			initialPage={0}
			paginationStep={16}
		/>
	);
};

export const MediaLibraryContainer = (props) => {
	return (
		<SearchableImageContainer
			searchCharacterLimit={1}
			type={customTabs.mediaLibrary}
			fetchAllImages={(page, successCb, errorCb) => getMediaLibrary('*', page, successCb, errorCb)}
			fetchImagesBySearch={getMediaLibrary}
			selectImage={props.selectImage}
			initialPage={0}
			paginationStep={16}
		/>
	);
};

export const ContentHubContainer = (props) => {
	const [contentHubData, setContentHubData] = React.useState(undefined);

	React.useEffect(() => {
		getContentHubData()
			.then((resp) => {
				if (resp.data && resp.data.length) {
					const data = resp.data[0];
					const respData = { metadataKey: data.Key };
					data.Attributes.forEach((item) => {
						if (item.Key === 'filters') {
							respData[item.Key] = JSON.parse(item.Value);
						} else {
							respData[item.Key] = item.Value;
						}
					});
					setContentHubData(respData);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const onDataChange = (data) => {
		setContentHubData(data);
	};

	const ReturnComponent = contentHubData ? ContentHubImages : ContentHubIntegration;
	return <ReturnComponent {...props} contentHubData={contentHubData} onDataChange={onDataChange} />;
};

export const UpgradeContainer = (props) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Heading size="lg" m="15px 0">
				Upgrade to unlock the following features:
			</Heading>

			<Box display="flex" flexDirection="column" alignItems="center">
				{props.restrictedTabs.map((item) => {
					return (
						<Text key={item} textAlign="left" p="4px 0" textTransform="capitalize" fontSize="xl">
							{item.replace(/-/g, ' ')}
						</Text>
					);
				})}
			</Box>

			<Button as={Link} target="_blank" href="/billing/plans" mt={2.5}>
				Upgrade
			</Button>
		</Box>
	);
};

export const RecentImage = (props) => {
	const selectImage = () => {
		if (props.selectImage) props.selectImage(props.Url, props.ContentType);
	};

	const removeImage = () => {
		if (props.removeImage) props.removeImage(props.Id);
	};

	return (
		<ImageComponent
			filename={props.OriginalFileName}
			url={props.Url}
			selectImage={selectImage}
			removeImage={removeImage}
			type={props.type}
		/>
	);
};

export const MediaLibraryImage = (props) => {
	const [imageUrl, setImageUrl] = React.useState(null);
	const filename = props?.innerItem?.displayName ?? generateGuid();
	const selectImage = () => {
		if (props.selectImage) props.selectImage(props.fullImageUrl, props.ContentType);
	};

	React.useEffect(() => {
		if (!imageUrl) {
			makeApiGatewayCall(props.url, 'get', null, null, {
				responseType: 'blob',
			}).then((res) => {
				if (res.status === 200) {
					setImageUrl(URL.createObjectURL(res.data));
				}
			});
		}

		return () => {
			if (imageUrl) {
				URL.revokeObjectURL(imageUrl);
			}
		};
	}, [props.url, imageUrl]);

	return <ImageComponent filename={filename} url={imageUrl} selectImage={selectImage} type={props.type} />;
};

export const UnsplashImage = (props) => {
	const selectImage = () => {
		if (props.selectImage) props.selectImage(props.links.download_location);
	};

	return <ImageComponent url={props.urls.small} selectImage={selectImage} user={props.user} />;
};

export const GiphyImage = (props) => {
	const selectImage = () => {
		if (props.selectImage) props.selectImage(props.images.original.url);
	};

	return (
		<ImageComponent
			url={props.images.original.url}
			selectImage={selectImage}
			// user={props.user}
		/>
	);
};

export const SearchableImageContainer = (props) => {
	const ImageComponents = {
		recent: RecentImage,
		unsplash: UnsplashImage,
		mediaLibrary: MediaLibraryImage,
		giphy: GiphyImage,
	};

	const [loader, setLoader] = React.useState(true);
	const [images, setImages] = React.useState([]);
	const [pagination, setPagination] = React.useState(props.initialPage);
	const [search, setSearch] = React.useState('');
	const [totalPages, setTotalPages] = React.useState(undefined);
	const debouncedSearch = useDebounce(search, 500);

	const errorCb = () => {
		showNotification({ type: NotificationTypes.ERROR, text: 'Could not get images' });
		setLoader(false);
	};

	let successCb = (data) => {
		if (data.TotalPages) setTotalPages(data.TotalPages);
		setImages(data.Items || data);
		setLoader(false);
	};

	let deleteErrorCb = () => {
		showNotification({ type: NotificationTypes.ERROR, text: 'Could not remove the image' });
	};

	let deleteSuccessCb = () => {
		showNotification({ type: NotificationTypes.SUCCESS, text: 'Image removed.' });
		if (debouncedSearch && debouncedSearch.length) {
			fetchImagesBySearch();
		} else {
			fetchAllImages();
		}
	};

	const fetchAllImages = (page = pagination) => {
		setLoader(true);
		return props.fetchAllImages(page, successCb, errorCb);
	};

	const removeImage = (id) => {
		if (props.removeImage) props.removeImage(id, deleteSuccessCb, deleteErrorCb);
	};

	const onInputChange = (e) => {
		setPagination(props.initialPage || 0);
		setSearch(e.target.value);
	};

	const paginate = (pageValue) => {
		const compareValue = props.paginationStep ? -1 : 0;
		if (pageValue > compareValue) {
			setPagination(pageValue);
			fetchImages(pageValue);
		}
	};

	const fetchImagesBySearch = (page = pagination) => {
		setLoader(true);
		return props.fetchImagesBySearch(search, page, successCb, errorCb);
	};

	const fetchImages = (page) => {
		if (debouncedSearch && debouncedSearch.length) {
			const searchCharacterLimit = props.searchCharacterLimit ?? 2;
			if (debouncedSearch.length > searchCharacterLimit) {
				fetchImagesBySearch(page);
			}
		} else {
			fetchAllImages(page);
		}
	};

	React.useEffect(fetchImages, [debouncedSearch]);

	const renderImages = () => {
		const DynamicComponent = ImageComponents[props.type];
		const paginationStep = props.paginationStep ? props.paginationStep : 1;
		const currentPagination = props.paginationStep ? pagination / props.paginationStep + 1 : pagination;

		return (
			<Box h="100%" position="relative">
				<FilterComponent
					disabled={loader}
					onChange={onInputChange}
					search={search}
					pagination={!images.length && search ? undefined : currentPagination}
					prev={() => paginate(pagination - paginationStep)}
					next={() => paginate(pagination + paginationStep)}
					maxPage={totalPages}
					images={images}
				/>
				{loader && (
					<Box h="88%" display="flex" alignItems="center" justifyContent="center">
						<LoaderComponent />
					</Box>
				)}
				{!loader && images && images.length ? (
					<Box display="flex" flexWrap="wrap" p={4} h="88%" overflow="auto">
						{images.map((item, i) => {
							return (
								<DynamicComponent
									{...item}
									selectImage={props.selectImage}
									removeImage={props.removeImage ? removeImage : undefined}
									key={i}
									type={props.type}
								/>
							);
						})}
					</Box>
				) : (
					<Text mt={12} display="flex" justifyContent="center">
						No images.
					</Text>
				)}
				{props.poweredBy && <PoweredBy {...props.poweredBy} />}
			</Box>
		);
	};

	return renderImages();
};

export const ImageComponent = (props) => {
	const [hovered, setHovered] = React.useState(false);
	// const [loading, setLoading] = React.useState(true);

	// React.useEffect(() => {
	// 	setLoading(true);
	// }, [props.url]);

	const imgSrc = props.src || props.url;

	return (
		<Box w="23%" h="fit-content" mx="1%" mb={5}>
			<Box
				border="1px solid"
				borderColor="#dddddd"
				h="fit-content"
				display="flex"
				flexDirection="column"
				borderRadius="base"
				overflow="hidden"
			>
				<Box
					display="flex"
					alignItems={'center'}
					h="36"
					overflow="hidden"
					justifyContent={'center'}
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					onClick={() => setHovered(true)}
					position="relative"
					bgImage={`url('${
						imgSrc
							? props.type && props.type === customTabs.recent
								? ImgixService.applyParametersToSrc(imgSrc, undefined, { transparency: 'grid' }, false, config.imgix_prefix)
								: imgSrc
							: undefined
					}')`}
					bgSize="cover"
					bgPosition="center"
					data-testid="image-component"
				>
					{/* <Image
						onLoad={() => setLoading(false)}
						d="block"
						visibility={loading ? 'hidden' : ''}
						w="fit-content"
						maxW={'100%'}
						src={props.src || props.url}
					/> */}
					{/* {loading && <Loader spinnerWidth={3} position="absolute" top="50%" left="50%" mt={-15} ml={-15} size={30} />} */}
					{hovered && (
						<Box
							position="absolute"
							flexDirection="column"
							top={0}
							right={0}
							left={0}
							bottom={0}
							display="flex"
							alignItems="center"
							justifyContent="center"
							bgColor="rgba(0, 0, 0, 0.4)"
						>
							<Button onClick={props.selectImage} data-testid="select-image-button">
								Select
							</Button>
							{props.removeImage && (
								<LinkWithParams mt={4} title="Delete" color="chakra-inverse-text" children="Delete" onClick={props.removeImage} />
							)}
						</Box>
					)}
				</Box>
				{(props.title || props.filename) && (
					<Box display="flex" flexDirection="column" p={2.5} alignItems="flex-start">
						<Box title={props.title} fontSize="sm" w="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
							{props.title}
						</Box>
						<Box title={props.filename} fontSize="sm" w="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
							{props.filename}
						</Box>
					</Box>
				)}
			</Box>
			{props.user && (
				<Box display="flex" justifyContent="space-between">
					<Text fontSize="xs">by</Text>
					<Link
						fontSize="xs"
						href={props.user.links.html + '?utm_source=moosend&utm_medium=referral&utm_campaign=api-credit'}
						target="_blank"
					>
						{props.user.name}
					</Link>
				</Box>
			)}
		</Box>
	);
};

export const FilterComponent = (props) => {
	return (
		<Box display="flex" h="12%" alignItems={'center'} pl={4} pr={16}>
			<SearchComponent search={props.search} onChange={props.onChange} disabled={props.disabled} />
			{props.pagination && (
				<PaginationComponent
					prev={props.prev}
					next={props.next}
					pagination={props.pagination}
					maxPage={props.maxPage}
					disabled={props.disabled}
					images={props.images}
				/>
			)}
		</Box>
	);
};

const PaginationComponent = (props) => {
	return (
		<Box display="flex" alignItems="center">
			<Tooltip label="Left">
				<IconButton
					aria-label="Left"
					variant="ghost"
					onClick={props.prev}
					isDisabled={props.disabled}
					icon={<Icon path={mdiChevronLeft} />}
				/>
			</Tooltip>

			<Text mx={2.5}>{Math.floor(props.pagination)}</Text>

			<Tooltip label="Right">
				<IconButton
					aria-label="Right"
					variant="ghost"
					onClick={props.next}
					isDisabled={props.disabled || props.pagination === props.maxPage || (props.images && props.images.length < 16)}
					icon={<Icon path={mdiChevronRight} />}
				/>
			</Tooltip>
		</Box>
	);
};

const SearchComponent = (props) => {
	return (
		<InputGroup mr={5} size="lg">
			<Input
				placeholder="Search for an image"
				onChange={props.disabled ? undefined : props.onChange}
				value={props.search}
				maxLength={300}
			/>
			<InputLeftElement>
				<Icon path={mdiMagnify} />
			</InputLeftElement>
		</InputGroup>
	);
};

export const LoaderComponent = () => {
	return (
		<Box display="flex" alignItems="center" justifyContent="center">
			<Spinner />
		</Box>
	);
};

const PoweredBy = (props) => {
	return (
		<Text position="absolute" bottom={0} left={0} right="4" bgColor="white" fontSize="xs" zIndex={1} display="flex" justifyContent="center">
			{props.label}
			{props.image && <Image src={props.image} w={20} />}
		</Text>
	);
};

export const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = React.useState(value);

	React.useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value]);

	return debouncedValue;
};

export default ImageSetting;
